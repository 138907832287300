/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowLeftSLine, 
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiStarFill,
  RiStarLine,
  RiInformationLine,
  RiPriceTag3Line,
  RiUserSmileLine,
  RiComputerLine,
  RiCustomerService2Line,
  RiExternalLinkLine,
  RiUserHeartLine,
  RiQuestionLine,
  RiSettings3Line,
  RiUserLine,
  RiPlugLine,
  RiBarChart2Line,
  RiPaintBrushLine,
  RiLineChartLine,
  RiQuestionAnswerLine
} from "react-icons/ri"

const LearningManagementSystemPage = () => {
  // Learning Management System comparison data
  const lmsComparison = [
    {
      name: "Docebo",
      website: "https://www.docebo.com",
      priceRange: "$$$",
      pricingModel: "Per user/month (annual contract)",
      userRating: 4.6,
      userReviews: 920,
      usability: 4.5,
      mobileFriendly: true,
      aiPowered: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "AI-powered learning",
        "Social learning features",
        "Extensive content marketplace",
        "Robust reporting and analytics",
        "Multi-language support"
      ],
      limitations: [
        "Higher price point",
        "Complex implementation",
        "Steeper learning curve for admins",
        "Some features require add-ons"
      ],
      bestFor: "Growing organizations seeking an advanced, scalable learning platform"
    },
    {
      name: "TalentLMS",
      website: "https://www.talentlms.com",
      priceRange: "$$",
      pricingModel: "Per user/month or active user plans",
      userRating: 4.7,
      userReviews: 850,
      usability: 4.8,
      mobileFriendly: true,
      aiPowered: false,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "User-friendly interface",
        "Course authoring tools",
        "Gamification elements",
        "Customizable certificates",
        "Strong integration options"
      ],
      limitations: [
        "Limited enterprise features",
        "Basic reporting capabilities",
        "Fewer advanced automation options",
        "Limited instructor-led features"
      ],
      bestFor: "Small to medium businesses looking for ease of use and quick implementation"
    },
    {
      name: "360Learning",
      website: "https://360learning.com",
      priceRange: "$$",
      pricingModel: "Per user/month (tiered plans)",
      userRating: 4.5,
      userReviews: 680,
      usability: 4.6,
      mobileFriendly: true,
      aiPowered: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Collaborative learning focus",
        "Discussion forums and social learning",
        "Simplified course creation",
        "Built-in coaching tools",
        "Learning groups and communities"
      ],
      limitations: [
        "Less robust than enterprise solutions",
        "Limited advanced customization",
        "Basic compliance features",
        "Reporting could be more comprehensive"
      ],
      bestFor: "Organizations focusing on collaborative and peer-to-peer learning"
    },
    {
      name: "Cornerstone Learning",
      website: "https://www.cornerstoneondemand.com/learning",
      priceRange: "$$$",
      pricingModel: "Per user/month (enterprise pricing)",
      userRating: 4.1,
      userReviews: 750,
      usability: 3.9,
      mobileFriendly: true,
      aiPowered: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Comprehensive talent suite integration",
        "Extensive content library",
        "Detailed compliance management",
        "Skills development focus",
        "Advanced reporting capabilities"
      ],
      limitations: [
        "Complex interface",
        "Significant implementation effort",
        "Higher cost",
        "Administrative overhead"
      ],
      bestFor: "Large enterprises needing a comprehensive talent development ecosystem"
    },
    {
      name: "SAP Litmos",
      website: "https://www.litmos.com",
      priceRange: "$$",
      pricingModel: "Per user/month (tiered plans)",
      userRating: 4.3,
      userReviews: 710,
      usability: 4.4,
      mobileFriendly: true,
      aiPowered: false,
      companySize: "Small to Enterprise",
      keyFeatures: [
        "Quick implementation",
        "Extensive course library",
        "Strong compliance tools",
        "Automation capabilities",
        "Commerce functionality"
      ],
      limitations: [
        "Reporting interface can be complex",
        "Limited customization options",
        "Some UI elements feel dated",
        "Additional modules increase cost"
      ],
      bestFor: "Organizations needing quick deployment with ready-made content"
    },
    {
      name: "LearnUpon",
      website: "https://www.learnupon.com",
      priceRange: "$$",
      pricingModel: "Tiered pricing based on active users",
      userRating: 4.8,
      userReviews: 580,
      usability: 4.7,
      mobileFriendly: true,
      aiPowered: false,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Multi-portal architecture",
        "Intuitive user interface",
        "Strong customer support",
        "Webinar integration",
        "Flexible assessment options"
      ],
      limitations: [
        "Limited advanced customization",
        "Fewer enterprise-level features",
        "Reporting could be more robust",
        "Limited content creation tools"
      ],
      bestFor: "Organizations prioritizing ease of use and excellent customer support"
    },
    {
      name: "Absorb LMS",
      website: "https://www.absorblms.com",
      priceRange: "$$$",
      pricingModel: "Per user/month plus setup fee",
      userRating: 4.5,
      userReviews: 620,
      usability: 4.3,
      mobileFriendly: true,
      aiPowered: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Modern, engaging interface",
        "Strong branding options",
        "Mercury AI module",
        "Robust e-commerce capabilities",
        "Extensive API options"
      ],
      limitations: [
        "Higher cost structure",
        "Setup fees can be significant",
        "Some advanced features cost extra",
        "Admin interface has steeper learning curve"
      ],
      bestFor: "Organizations wanting a highly customizable, branded learning experience"
    },
    {
      name: "iSpring Learn",
      website: "https://www.ispringsolutions.com/ispring-learn",
      priceRange: "$$",
      pricingModel: "Per user/month (annual billing)",
      userRating: 4.6,
      userReviews: 490,
      usability: 4.7,
      mobileFriendly: true,
      aiPowered: false,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Powerful authoring tools",
        "Fast implementation",
        "Offline mobile access",
        "User-friendly interface",
        "Strong PowerPoint integration"
      ],
      limitations: [
        "Limited advanced features",
        "Basic gamification options",
        "Fewer integration options",
        "Less robust than enterprise platforms"
      ],
      bestFor: "Organizations needing strong content creation tools with easy deployment"
    },
    {
      name: "Moodle Workplace",
      website: "https://moodle.com/workplace",
      priceRange: "$$",
      pricingModel: "Custom pricing based on users and features",
      userRating: 4.0,
      userReviews: 560,
      usability: 3.8,
      mobileFriendly: true,
      aiPowered: false,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Open source flexibility",
        "Advanced customization options",
        "Multi-tenancy capability",
        "Comprehensive reporting",
        "Competency frameworks"
      ],
      limitations: [
        "Steeper learning curve",
        "Interface less polished than competitors",
        "Requires technical expertise",
        "Implementation can be complex"
      ],
      bestFor: "Organizations seeking ultimate customization and open-source benefits"
    },
    {
      name: "Lessonly by Seismic",
      website: "https://www.lessonly.com",
      priceRange: "$$",
      pricingModel: "Per user/month (tiered)",
      userRating: 4.7,
      userReviews: 530,
      usability: 4.8,
      mobileFriendly: true,
      aiPowered: false,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Practice and coaching tools",
        "Simple, intuitive interface",
        "Seamless feedback capabilities",
        "Sales enablement focus",
        "Engaging content creation"
      ],
      limitations: [
        "Limited advanced enterprise features",
        "Basic reporting capabilities",
        "Fewer compliance-specific tools",
        "Less content marketplace integration"
      ],
      bestFor: "Sales and customer service teams needing practice-based learning"
    }
  ];

  // Helper function to render star ratings
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.3;
    
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary' }} />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary', opacity: 0.5 }} />);
      } else {
        stars.push(<RiStarLine key={i} sx={{ color: 'primary' }} />);
      }
    }
    
    return <div sx={{ display: 'flex', alignItems: 'center' }}>{stars} <span sx={{ ml: 1 }}>({rating}/5)</span></div>;
  };

  return (
    <Layout>
      <SEO 
        title="Top 10 Learning Management Systems (LMS) Compared | HR Software Guide"
        description="Compare the top 10 Learning Management System (LMS) solutions for 2025. Detailed analysis of features, pricing, usability, and user feedback to find the best solution for your organization"
        keywords={[
          "learning management system",
          "LMS comparison",
          "employee training software",
          "best LMS platforms",
          "corporate learning software",
          "e-learning solutions",
          "training management systems"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4
          }}>
            <Link to="/software" sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary',
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 500
            }}>
              <RiArrowLeftSLine sx={{ mr: 1 }} /> Back to HR Software
            </Link>
          </div>
          
          <div sx={{
            textAlign: 'center',
            mb: 2,
            fontSize: '0.95rem',
            color: 'text',
            opacity: 0.8
          }}>
            Last Updated: March 2025
          </div>
          
          <h1 sx={{
            fontSize: ['1.8rem', '2.3rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            Top 10 Learning Management Systems Compared
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            An in-depth comparison of the leading learning management systems to help you find the right
            LMS solution for your organization's training needs, user experience, and budget.
          </p>
          
          {/* Comparison criteria explanation */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: '1.3rem',
              fontWeight: 600,
              mb: 3,
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiInformationLine sx={{ mr: 2, color: 'primary' }} /> Comparison Criteria
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
              gap: 3,
              mb: 3
            }}>
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiPriceTag3Line sx={{ mr: 1, color: 'primary' }} /> Pricing
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  $ = Budget (Under $5/user/mo)<br />
                  $$ = Mid-range ($5-15/user/mo)<br />
                  $$$ = Premium ($15-30/user/mo)<br />
                  $$$$ = Enterprise (Custom pricing)
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiUserSmileLine sx={{ mr: 1, color: 'primary' }} /> User Rating
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Based on verified reviews across G2, Capterra, and eLearning Industry. Rating out of 5.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiComputerLine sx={{ mr: 1, color: 'primary' }} /> Usability
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Ease of use rating based on learner experience, admin interface, and course creation capabilities.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiCustomerService2Line sx={{ mr: 1, color: 'primary' }} /> Company Size
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  The ideal organization size where the LMS performs best based on features, scalability, and pricing.
                </p>
              </div>
            </div>
          </div>
          
          {/* LMS comparison cards will go here */}
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', null, '1fr 1fr'],
            gap: 4,
            mb: 5
          }}>
            {lmsComparison.map((lms, index) => (
              <div key={`lms-${lms.name}`} sx={{
                bg: 'white',
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{
                  p: 3,
                  borderBottom: '1px solid',
                  borderColor: 'border'
                }}>
                  <div sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <h2 sx={{
                      fontSize: ['1.2rem', '1.3rem'],
                      fontWeight: 600,
                      mb: 1,
                      color: 'primary'
                    }}>
                      {lms.name}
                    </h2>
                    <div sx={{
                      bg: 'primaryMuted',
                      px: 2,
                      py: 1,
                      borderRadius: '20px',
                      fontSize: '0.8rem',
                      fontWeight: 600,
                      color: 'primary'
                    }}>
                      {lms.priceRange}
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                  }}>
                    <div sx={{ display: 'flex', color: 'amber' }}>
                      {renderStars(lms.userRating)}
                    </div>
                    <div sx={{ ml: 2, fontSize: '0.9rem', opacity: 0.8 }}>
                      {lms.userRating} ({lms.userReviews} reviews)
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 3,
                    mb: 3
                  }}>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Pricing Model</div>
                      <div sx={{ fontSize: '0.9rem' }}>{lms.pricingModel}</div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Usability</div>
                      <div sx={{ fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
                        {renderStars(lms.usability)}
                        <span sx={{ ml: 1 }}>{lms.usability}/5</span>
                      </div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Mobile Friendly</div>
                      <div sx={{ fontSize: '0.9rem' }}>{lms.mobileFriendly ? 'Yes' : 'No'}</div>
                    </div>
                    <div>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>AI Powered</div>
                      <div sx={{ fontSize: '0.9rem' }}>{lms.aiPowered ? 'Yes' : 'No'}</div>
                    </div>
                    <div sx={{ gridColumn: '1 / span 2' }}>
                      <div sx={{ fontSize: '0.85rem', fontWeight: 500, mb: 1 }}>Company Size</div>
                      <div sx={{ fontSize: '0.9rem' }}>{lms.companySize}</div>
                    </div>
                  </div>
                  
                  <a href={lms.website} target="_blank" rel="noopener noreferrer" sx={{
                    display: 'block',
                    width: '100%',
                    py: 2,
                    textAlign: 'center',
                    bg: 'primary',
                    color: 'white',
                    fontWeight: 500,
                    borderRadius: '5px',
                    textDecoration: 'none',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                    ':hover': {
                      bg: 'primaryDark',
                      color: 'white',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }
                  }}>
                    Visit Website <RiExternalLinkLine sx={{ ml: 1, verticalAlign: 'middle' }} />
                  </a>
                </div>
                
                <div sx={{ p: 3, bg: 'background', flex: 1 }}>
                  <div sx={{ mb: 3 }}>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiCheckboxCircleLine sx={{ mr: 1, color: 'success' }} /> Key Features
                    </h3>
                    <ul sx={{ pl: 3, mb: 0 }}>
                      {lms.keyFeatures.map((feature, i) => (
                        <li key={`feature-${lms.name}-${i}`} sx={{ mb: 1, fontSize: '0.9rem' }}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                  
                  <div sx={{ mb: 3 }}>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiCloseCircleLine sx={{ mr: 1, color: 'danger' }} /> Limitations
                    </h3>
                    <ul sx={{ pl: 3, mb: 0 }}>
                      {lms.limitations.map((limitation, i) => (
                        <li key={`limitation-${lms.name}-${i}`} sx={{ mb: 1, fontSize: '0.9rem' }}>{limitation}</li>
                      ))}
                    </ul>
                  </div>
                  
                  <div>
                    <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                      <RiUserHeartLine sx={{ mr: 1, color: 'primary' }} /> Best For
                    </h3>
                    <p sx={{ mb: 0, fontSize: '0.9rem' }}>{lms.bestFor}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* LMS Selection Guide */}
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
            p: 4,
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.4rem', '1.5rem'],
              fontWeight: 600,
              mb: 4,
              color: 'primary',
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiQuestionLine sx={{ mr: 2 }} /> LMS Selection Guide
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr'],
              gap: 4
            }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiSettings3Line sx={{ mr: 1, color: 'primary' }} /> Assess Learning Strategy
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Evaluate your organization's learning needs and philosophy. Do you need compliance training, professional development, 
                  onboarding, or a mix? Different LMS platforms excel in specific training approaches (instructor-led, 
                  self-paced, blended, or social learning).
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiUserLine sx={{ mr: 1, color: 'primary' }} /> Prioritize User Experience
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  The most feature-rich LMS will fail if learners find it difficult to use. Prioritize intuitive 
                  interfaces, mobile responsiveness, and accessible design. Consider the learning curve for administrators 
                  who will manage content and reporting.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiPlugLine sx={{ mr: 1, color: 'primary' }} /> Integration Requirements
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Determine which systems your LMS needs to connect with: HRIS, talent management, 
                  content libraries, webinar platforms, or authentication systems. Look for pre-built 
                  integrations or robust API capabilities.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiBarChart2Line sx={{ mr: 1, color: 'primary' }} /> Analytics Capabilities
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Consider what data you need to track: completion rates, assessment scores, engagement metrics, 
                  or ROI. Advanced LMS platforms offer customizable dashboards, automated reporting, and learning 
                  analytics to help identify knowledge gaps and training effectiveness.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiPaintBrushLine sx={{ mr: 1, color: 'primary' }} /> Content Creation Tools
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  If you'll be creating content in-house, evaluate built-in authoring capabilities. Some LMS platforms 
                  offer robust course builders, while others require external authoring tools. Check supported content 
                  standards (SCORM, xAPI, cmi5) and multimedia capabilities.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RiLineChartLine sx={{ mr: 1, color: 'primary' }} /> Scalability & Growth
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Choose an LMS that can grow with your organization. Consider factors like user capacity, 
                  multi-language support, geolocation capabilities, and pricing models that accommodate 
                  expansion without prohibitive cost increases.
                </p>
              </div>
            </div>
          </div>
          
          {/* Learning Technology Trends */}
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
            p: 4,
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.4rem', '1.5rem'],
              fontWeight: 600,
              mb: 4,
              color: 'primary',
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiLineChartLine sx={{ mr: 2 }} /> Learning Technology Trends
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr', '1fr 1fr 1fr'],
              gap: 4
            }}>
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Adaptive Learning
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  AI-powered systems that personalize learning paths based on performance, preferences, and 
                  learning style. Advanced platforms adjust content difficulty and focus areas in real-time.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Microlearning
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Short, focused learning modules (3-5 minutes) designed for mobile consumption and spaced repetition. 
                  Leading LMS platforms now support microlearning content delivery and tracking.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  XR Integration
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Virtual, augmented, and mixed reality experiences integrated with learning platforms for 
                  immersive training. Particularly valuable for safety, technical, and complex procedural training.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Learning Analytics
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Advanced data visualization and predictive analytics to identify learning patterns, 
                  knowledge gaps, and ROI. Modern LMS platforms provide actionable insights beyond basic completion metrics.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Social Learning
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Collaborative features that enable peer-to-peer learning, knowledge sharing, and communities of practice. 
                  Includes discussion forums, expert directories, content sharing, and collaborative workspaces.
                </p>
              </div>
              
              <div sx={{
                bg: 'background',
                p: 3,
                borderRadius: '8px'
              }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  Learning Experience Platforms
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  The evolution beyond traditional LMS, focusing on personalized learning journeys, content curation, 
                  and skills development. LXPs emphasize learner engagement and self-directed discovery.
                </p>
              </div>
            </div>
          </div>
          
          {/* FAQs */}
          <div sx={{
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.4rem', '1.5rem'],
              fontWeight: 600,
              mb: 4,
              color: 'primary',
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiQuestionAnswerLine sx={{ mr: 2 }} /> Frequently Asked Questions
            </h2>
            
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
              mb: 3
            }}>
              <div sx={{ p: 3 }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  What's the difference between an LMS and an LXP?
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  A Learning Management System (LMS) primarily focuses on delivering, tracking, and managing formal learning content. 
                  It excels at compliance training and structured courses. A Learning Experience Platform (LXP) emphasizes content discovery, 
                  personalized learning paths, and social/collaborative learning. LXPs often include AI-driven recommendations and support 
                  both formal and informal learning.
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
              mb: 3
            }}>
              <div sx={{ p: 3 }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  How much does an LMS typically cost?
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  LMS pricing varies widely based on functionality, user count, and deployment model. Cloud-based systems typically 
                  charge per user per month (ranging from $3-$30/user/month) with volume discounts. Many providers offer tiered plans 
                  with increasing features. Additional costs may include implementation, customization, and content development. 
                  Open-source options like Moodle have no license fees but require hosting and technical expertise.
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 3px 10px rgba(0,0,0,0.08)'
            }}>
              <div sx={{ p: 3 }}>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
                  What content formats do modern LMS platforms support?
                </h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, mb: 0 }}>
                  Most modern LMS platforms support a wide range of content formats, including: SCORM, xAPI (Tin Can), and cmi5 
                  compliant packages; video (MP4, WebM); documents (PDF, DOCX); presentations (PPT, Google Slides); 
                  HTML5 content; audio files; quizzes and assessments; virtual classrooms and webinars; and interactive 
                  simulations. Many also support embedding external content from YouTube, Vimeo, and other platforms.
                </p>
              </div>
            </div>
          </div>
          
          {/* CTA */}
          <div sx={{
            textAlign: 'center',
            mb: 4
          }}>
            <h2 sx={{ fontSize: '1.4rem', fontWeight: 600, mb: 3 }}>
              Still researching HR software options?
            </h2>
            <p sx={{ mb: 4, maxWidth: '800px', mx: 'auto' }}>
              Explore our other in-depth comparison guides to find the right software solutions for your HR needs.
            </p>
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/software/hris" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                HRIS Comparison
              </Link>
              <Link to="/software/applicant-tracking-system/" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                ATS Comparison
              </Link>
              <Link to="/software/performance-management" sx={{
                py: 2,
                px: 3,
                bg: 'primary',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                ':hover': {
                  bg: 'primaryDark',
                  color: 'white',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                Performance Management Comparison
              </Link>
            </div>
          </div>
          
          {/* Get Expert Help CTA */}
          <div sx={{
            bg: 'primary',
            borderRadius: '12px',
            p: 4,
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 6
          }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'], 
              fontWeight: 700, 
              color: 'white',
              mb: 3 
            }}>
              Need Help Selecting the Right Learning Management System?
            </h2>
            <p sx={{ 
              fontSize: ['1rem', '1.1rem'], 
              color: 'white',
              mb: 4,
              opacity: 0.9
            }}>
              Our HR technology experts can provide personalized recommendations based on your organization's 
              learning objectives, content needs, and technical requirements.
            </p>
            <div sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/contact" sx={{
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  color: 'primary'
                }
              }}>
                Schedule a Free Consultation
              </Link>
              <Link to="/services/hr-software-selection" sx={{
                bg: 'transparent',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                border: '2px solid white',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Learn About Our Selection Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LearningManagementSystemPage; 